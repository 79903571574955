import React from "react"
import Benefits from "../components/benefits/Benefits"
import Care from "../components/care/Care"
import Faq from "../components/faq/Faq"
import Hero from "../components/hero/Hero"
import Layout from "../components/layout/Layout"
import Opinions from "../components/opinions/Opinions"
import Opinions2 from "../components/opinions2/Opinions"
// import Quality from "../components/quality/Quality"
import Seo from "../components/seo"
import TrustedUs from "../components/trustedUs/TrustedUs"
import WhyUs from "../components/whyUs/WhyUs"
import Contact from "../components/contact/Contact"
// import ArrowUp from '../images/wroc-do-gory.svg'
// import { isHeroScrolledShowElement } from '../ultis/functions'
import Benefits2 from "../components/benefits/Benefits2"
import About from "../components/about/About"
import Branches from "../components/branches/branches"
import Benefits3 from "../components/benefits/Benefits3"

const IndexPage = () => {
  // const scrollUp = () => {
  //   if (typeof window !== "undefined") {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  //   }
  // }
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const elementHero = document.querySelector('.hero');
  //     const elementToShow = document.querySelector('.up');
  //     isHeroScrolledShowElement(elementHero, elementToShow)
  //   }
  // }, [])

  return (
    <Layout className="home--2">
      <div className="home--v2">
        <Seo title="" />
        <Hero />
        <Contact />
        {/* todo */}
        <Benefits3 />
        {/* <WhyUs /> */}
        {/* <Benefits2 /> */}
        <Opinions2/>
        <Opinions />

        <Care />
        {/* <About /> */}
        {/* <Quality /> */}
        <div className="container" id="about2">
          <Branches />
        </div>

        <TrustedUs />
        <Faq />
        {/* <div className="up">
        <a href="#" onClick={(e => { e.preventDefault(); scrollUp() })}>
        <ArrowUp />
        </a>
      </div> */}
      </div>
    </Layout>
  )
}

export default IndexPage
