import React, { useContext } from "react"
import Heading from "../heading/Heading"
import "./style.scss"
import { StaticImage } from "gatsby-plugin-image"
import Img from "../../images/oddziały.svg"
import Icon from "../../images/branches_icon.svg"
import IconVideo from "../../images/video_icon.svg"
import { AppContext } from "../context/AppContext"

const Branches = () => {
  const { mousemove } = useContext(AppContext)

  return (
    <section className="branches">
      <div className="branches__wrapper">
        <div className="branches__col branches__col--content">
          <Heading
            text="Nowoczesne oddziały"
            size="XXl"
            type="h2"
            className="branches__heading"
          />
          <div className="branches__content">
            <ul>
              <li>
                <Icon />
                <span>
                  <strong>nowoczesny i przyjemny</strong> wystrój
                </span>
              </li>
              <li>
                <Icon />
                <span>sprzęt diagnostyczny najnowszej generacji</span>
              </li>
              <li>
                <Icon />
                <span>dogodny dojazd komunikacją miejską</span>
              </li>
              <li>
                <Icon />
                <span>
                  wygodny parking dla <strong>klientów</strong>
                </span>
              </li>
              <li>
                <Icon />
                <span>
                  wnętrza przystosowane do potrzeb osób niepełnosprawnych
                </span>
              </li>
            </ul>
          </div>
          <div className="branches__video">
            <div className="branches__video__icon">
              <IconVideo />
            </div>
            {!mousemove ? (
              <div className="branches__images">
                <StaticImage
                  alt="pakiety medyczne opieka medyczna benefity dla pracowników Korzyści opieki medycznej dla Twojej firmy"
                  loading="lazy"
                  src="../../images/korzysci.png"
                />
              </div>
            ) : (
              <div className="branches__video">
                <iframe
                  src="https://www.youtube.com/embed/Sh865tAm70g"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </div>
        <div className="branches__col branches__col--img">
          <Img />
        </div>
      </div>
    </section>
  )
}

export default Branches
