import React from "react"
import Heading from "../heading/Heading"
import "./style.scss"
// import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery } from "gatsby"

const Benefits3 = () => {
  const data = useStaticQuery(
    graphql`
      query BenefitsData3 {
        contentJson(type: { eq: "benefits3" }) {
          heading
          images {
            id
            childImageSharp {
              gatsbyImageData(width: 645)
            }
          }
          alt
          title
          items {
            heading
            description
            source
          }
        }
      }
    `
  )
  const { heading, items, images, alt } = data.contentJson

  return (
    <section className="benefits benefits--3">
      <div id="benefits" />
      <div className="container">
        <div className="benefits__wrapper">
          <div className="benefits__column benefits__column--content">
            <Heading
              className="benefits__heading"
              text={heading}
              type="2"
              size="XL"
            />
            <ul className="benefits__list">
              {items?.map((item, key) => (
                <li
                  key={`benefits__list__${key}`}
                  className="benefits__list__item"
                >
                  <div>
                    <p
                      className="benefits__list__item__heading size--Big"
                      dangerouslySetInnerHTML={{
                        __html: item.heading,
                      }}
                    />
                    {item.description ? (
                      <p
                        className="benefits__list__item__para size--Normal"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {item.source ? (
                      <p
                        className="benefits__list__item__source"
                        dangerouslySetInnerHTML={{
                          __html: item.source,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="benefits__column benefits__column--img">
            <StaticImage
              src="../../images/korzysci-benefitu.png"
              alt={alt}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits3
